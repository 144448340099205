<template>
  <div class="Firstcontrol drag" v-draw :style="scaleHeight">
    <div class="evaluate-header">
      热风炉_{{ historyname }}
      <div class="evaluate-header-icon" @click="closeCompon">
        <span class="el-icon-close"></span>
      </div>
    </div>
    <div class="Firstcontrolflex flex">
      <!-- 先控左边数据开始 -->
      <div class="Firstcontrolflex-left">
        <div class="left-tit">
          {{ titname }}
        </div>
        <div class="left-list flex">
          <div class="list-flex1 flex align-cen">
            <div class="list-flex1-text"  @click="tocomment('SP', '#25495e')">SP</div>
            <div class="list-flex1-num" @click="toIpt(infoList[node].SP, '点击按钮', 'SP', node)">
              {{ infoList[node].SP }}
              <!-- {{ isIndex === 1 ? infoList.V_GONGGONG__p__ZGXK01.SP : isIndex }} -->
            </div>
          </div>
          <div class="list-flex1 flex align-cen">
            <div
              class="list-flex1-num"
              :style="{ color: infoList[node].RM == 1 ? '#2AFC30' : '' }"
              @click="chaneStu(1, 'RM', node)"
            >
              自动
            </div>
          </div>
        </div>
        <div class="left-list flex">
          <div class="list-flex1 flex align-cen">
            <div class="list-flex1-text" @click="tocomment('PV', '#0de0ac')">PV</div>
            <div
              class="list-flex1-num"
              style="color: #0de0ac"
              @click="toIpt(infoList[node].PV, '点击按钮', 'PV', node)"
            >
              {{ infoList[node].PV }}
            </div>
          </div>
          <div class="list-flex1 flex align-cen">
            <div
              class="list-flex1-num"
              @click="chaneStu(0, 'RM', node)"
              :style="{ color: infoList[node].RM == 0 ? '#2AFC30' : '' }"
            >
              手动
            </div>
          </div>
        </div>
        <div class="left-list flex">
          <div class="list-flex1 flex align-cen">
            <div class="list-flex1-text" @click="tocomment('AV', '#ec163e')">AV</div>
            <div
              class="list-flex1-num"
              style="color: #ec163e"
               @click="toIpt(infoList[node].AV, '点击按钮', 'AV', node)"
            >
              {{ infoList[node].AV }}
            </div>
          </div>
          <div class="list-flex1 flex align-cen">
            <div
              class="list-flex1-num"
              @click="chaneStu(3, 'RM', node)"
              :style="{
                color:
                  infoList[node].RM == 3 || infoList[node].RM == 4
                    ? '#2AFC30'
                    : '',
              }"
            >
              跟踪
            </div>
          </div>
        </div>
        <div class="left-list flex">
          <div class="list-flex1 flex align-cen" style="visibility: hidden">
            <div class="list-flex1-min">-5</div>
            <div class="list-flex1-min">-1</div>
            <div class="list-flex1-min">+1</div>
            <div class="list-flex1-min">+5</div>
          </div>
          <div class="list-flex1 flex align-cen">
            <div
              class="list-flex1-num"
              @click="toIpt(infoList[node].AD, '点击按钮', 'AD', node)"
            >
              {{ infoList[node].AD ? "反作用" : "正作用" }}
            </div>
          </div>
        </div>
        <div class="left-list flex">
          <div class="list-flex1 flex align-cen">
            <div class="list-flex1-text">PVMD</div>
            <div
              class="list-flex1-num list-flex1-num1"
              @click="
                toIpt(infoList[node].PVMD, '测量值量程下限', 'PVMD', node)
              "
            >
              {{ infoList[node].PVMD }}
            </div>
          </div>
          <div class="list-flex1 flex align-cen">
            <div class="list-flex1-text">PVMU</div>
            <div
              class="list-flex1-num list-flex1-num1"
              @click="
                toIpt(infoList[node].PVMU, '测量值量程上限', 'PVMU', node)
              "
            >
              {{ infoList[node].PVMU }}
            </div>
          </div>
        </div>
        <div class="left-list flex">
          <div class="list-flex1 flex align-cen">
            <div class="list-flex1-text">MD</div>
            <div
              class="list-flex1-num list-flex1-num1"
              @click="toIpt(infoList[node].MD, '输出控制量程下限', 'MD', node)"
            >
              {{ infoList[node].MD }}
            </div>
          </div>
          <div class="list-flex1 flex align-cen">
            <div class="list-flex1-text">MU</div>
            <div
              class="list-flex1-num list-flex1-num1"
              @click="toIpt(infoList[node].MU, '测量值量程上限', 'MU', node)"
            >
              {{ infoList[node].MU }}
            </div>
          </div>
        </div>
        <div class="left-list flex">
          <div class="list-flex1 flex align-cen">
            <div class="list-flex1-text">OUTB</div>
            <div
              class="list-flex1-num list-flex1-num1"
              @click="
                toIpt(
                  infoList[node].OUTB,
                  '控制输出下限，该值必须在MD和MU之间',
                  'OUTB',
                  node
                )
              "
            >
              {{ infoList[node].OUTB }}
            </div>
          </div>
          <div class="list-flex1 flex align-cen">
            <div class="list-flex1-text">OUTT</div>
            <div
              class="list-flex1-num list-flex1-num1"
              @click="
                toIpt(
                  infoList[node].OUTT,
                  '控制输出下限，该值必须在MD和MU之间',
                  'OUTT',
                  node
                )
              "
            >
              {{ infoList[node].OUTT }}
            </div>
          </div>
        </div>
        <div class="left-list flex">
          <div class="list-flex1 flex align-cen">
            <div class="list-flex1-text">ORL</div>
            <div
              class="list-flex1-num list-flex1-num1"
              @click="
                toIpt(
                  infoList[node].OUTRL,
                  '输出最小幅度限幅，该值为MU-MD的百分比',
                  'OUTRL',
                  node
                )
              "
            >
              {{ infoList[node].OUTRL }}
            </div>
          </div>
          <div class="list-flex1 flex align-cen">
            <div class="list-flex1-text">RTH</div>
            <div
              class="list-flex1-num list-flex1-num1"
              @click="
                toIpt(
                  infoList[node].RTH,
                  '输出最速率限幅，该值为MU-MD的百分比',
                  'RTH',
                  node
                )
              "
            >
              {{ infoList[node].RTH }}
            </div>
          </div>
        </div>
        <div class="left-list flex">
          <div class="list-flex1 flex align-cen">
            <div class="list-flex1-text">AV_P</div>
            <div class="list-flex1-num defultcolor1">
              {{ infoList[node].AV_P }}
            </div>
          </div>
          <div class="list-flex1 flex align-cen">
            <div class="list-flex1-text">AV_R</div>
            <div class="list-flex1-num defultcolor2">
              {{ infoList[node].AV_R }}
            </div>
          </div>
        </div>
        <div class="left-list flex">
          <div class="list-flex1 flex align-cen">
            <div class="list-flex1-text">AV_GC</div>
            <div class="list-flex1-num defultcolor3">
              {{ infoList[node].AV_GC }}
            </div>
          </div>
          <div class="list-flex1 flex align-cen">
            <div class="list-flex1-text">AV_J</div>
            <div class="list-flex1-num defultcolor4">
              {{ infoList[node].AV_J }}
            </div>
          </div>
        </div>
        <div class="left-list flex">
          <div class="list-flex1 flex align-cen">
            <div class="list-flex1-text">AV_C</div>
            <div class="list-flex1-num defultcolor5">
              {{ infoList[node].AV_C }}
            </div>
          </div>
          <div class="list-flex1 flex align-cen">
            <div class="list-flex1-text">AV_D</div>
            <div class="list-flex1-num defultcolor6">
              {{ infoList[node].AV_D }}
            </div>
          </div>
        </div>
        <div class="left-list flex">
          <div class="list-flex1 flex align-cen">
            <div class="list-flex1-text">TC</div>
            <div
              class="list-flex1-num list-flex1-num1"
              @click="
                toIpt(infoList[node].TC, '扰动观测器采样时间', 'TC', node)
              "
            >
              {{ infoList[node].TC }}
            </div>
          </div>
          <div class="list-flex1 flex align-cen">
            <div class="list-flex1-text">GC1</div>
            <div
              class="list-flex1-num list-flex1-num1"
              @click="
                toIpt(infoList[node].GC1, '扰动观测器静态作用', 'GC1', node)
              "
            >
              {{ infoList[node].GC1 }}
            </div>
          </div>
        </div>
        <div class="left-list flex">
          <div class="list-flex1 flex align-cen">
            <div class="list-flex1-text">TZ</div>
            <div
              class="list-flex1-num list-flex1-num1"
              @click="
                toIpt(infoList[node].TZ, '扰动观测器专家时间', 'TZ', node)
              "
            >
              {{ infoList[node].TZ }}
            </div>
          </div>
          <div class="list-flex1 flex align-cen">
            <div class="list-flex1-text">GC2</div>
            <div
              class="list-flex1-num list-flex1-num1"
              @click="
                toIpt(infoList[node].GC2, '扰动观测器动态作用', 'GC2', node)
              "
            >
              {{ infoList[node].GC2 }}
            </div>
          </div>
        </div>
        <div class="left-list flex">
          <div class="list-flex1 flex align-cen">
            <div class="list-flex1-text">OUTL</div>
            <div
              class="list-flex1-num list-flex1-num1"
              @click="
                toIpt(
                  infoList[node].OUTL,
                  '扰动观测器输出下限，先控模块并联时限制',
                  'OUTL',
                  node
                )
              "
            >
              {{ infoList[node].OUTL }}
            </div>
          </div>
          <div class="list-flex1 flex align-cen">
            <div class="list-flex1-text">OUTH</div>
            <div
              class="list-flex1-num list-flex1-num1"
              @click="
                toIpt(
                  infoList[node].OUTH,
                  '扰动观测器输出下限，先控模块并联时限制',
                  'OUTH',
                  node
                )
              "
            >
              {{ infoList[node].OUTH }}
            </div>
          </div>
        </div>
        <div class="left-list flex">
          <div class="list-flex1 flex align-cen">
            <div class="list-flex1-text">TC</div>
            <div
              class="list-flex1-num list-flex1-num1"
              @click="
                toIpt(infoList[node].TC_CD, '重叠控制采样时间', 'TC_CD', node)
              "
            >
              {{ infoList[node].TC_CD }}
            </div>
          </div>
          <div class="list-flex1 flex align-cen">
            <div class="list-flex1-text">CDGD</div>
            <div
              class="list-flex1-num list-flex1-num1"
              @click="
                toIpt(infoList[node].CD_GD, '重叠控制作用拐点', 'CD_GD', node)
              "
            >
              {{ infoList[node].CD_GD }}
            </div>
          </div>
        </div>
        <div class="left-list flex">
          <div class="list-flex1 flex align-cen">
            <div class="list-flex1-text">TZ</div>
            <div
              class="list-flex1-num list-flex1-num1"
              @click="
                toIpt(infoList[node].TZ_CD, '重叠控制专家时间', 'TZ_CD', node)
              "
            >
              {{ infoList[node].TZ_CD }}
            </div>
          </div>
          <div class="list-flex1 flex align-cen">
            <div class="list-flex1-text">CDKJ</div>
            <div
              class="list-flex1-num list-flex1-num1"
              @click="
                toIpt(
                  infoList[node].CD_K_J,
                  '重叠控制，扰动观测器静态系数',
                  'CD_K_J',
                  node
                )
              "
            >
              {{ infoList[node].CD_K_J }}
            </div>
          </div>
        </div>
        <div class="left-list flex">
          <div class="list-flex1 flex align-cen">
            <div class="list-flex1-text">CDK1</div>
            <div
              class="list-flex1-num list-flex1-num1"
              @click="
                toIpt(
                  infoList[node].CD_K_FD,
                  '重叠控制，输出幅度系数',
                  'CD_K_FD',
                  node
                )
              "
            >
              {{ infoList[node].CD_K_FD }}
            </div>
          </div>
          <div class="list-flex1 flex align-cen">
            <div class="list-flex1-text">CDKD</div>
            <div
              class="list-flex1-num list-flex1-num1"
              @click="
                toIpt(
                  infoList[node].CD_K_D,
                  '重叠控制，扰动观测器动态系数',
                  'CD_K_D',
                  node
                )
              "
            >
              {{ infoList[node].CD_K_D }}
            </div>
          </div>
        </div>
        <div class="left-list flex">
          <div class="list-flex1 flex align-cen">
            <div class="list-flex1-text">CDK2</div>
            <div
              class="list-flex1-num list-flex1-num1"
              @click="
                toIpt(infoList[node].CD_K, '重叠控制输出保留系数', 'CD_K', node)
              "
            >
              {{ infoList[node].CD_K }}
            </div>
          </div>
          <div class="list-flex1 flex align-cen">
            <div class="list-flex1-text">CDBH</div>
            <div
              class="list-flex1-num list-flex1-num1"
              @click="
                toIpt(
                  infoList[node].CD_BH,
                  '重叠控制扰动观测器输出',
                  'CD_BH',
                  node
                )
              "
            >
              {{ infoList[node].CD_BH }}
            </div>
          </div>
        </div>
        <div class="left-list flex">
          <div class="list-flex1 flex align-cen">
            <div class="list-flex1-text">CDL</div>
            <div
              class="list-flex1-num list-flex1-num1"
              @click="
                toIpt(infoList[node].CDL, '重叠控制输出下限', 'CDL', node)
              "
            >
              {{ infoList[node].CDL }}
            </div>
          </div>
          <div class="list-flex1 flex align-cen">
            <div class="list-flex1-text">CDH</div>
            <div
              class="list-flex1-num list-flex1-num1"
              @click="
                toIpt(infoList[node].CDH, '重叠控制输出上限', 'CDH', node)
              "
            >
              {{ infoList[node].CDH }}
            </div>
          </div>
        </div>
        <div class="left-list flex">
          <div class="list-flex1 flex align-cen">
            <div class="list-flex1-text">XGL1H</div>
            <div
              class="list-flex1-num list-flex1-num1"
              @click="
                toIpt(
                  infoList[node].XGL1_H,
                  '自适应相关变量1上限',
                  'XGL1_H',
                  node
                )
              "
            >
              {{ infoList[node].XGL1_H }}
            </div>
          </div>
          <div class="list-flex1 flex align-cen">
            <div class="list-flex1-text">XGL1L</div>
            <div
              class="list-flex1-num list-flex1-num1"
              @click="
                toIpt(
                  infoList[node].XGL1_L,
                  '自适应相关变量1下限',
                  'XGL1_L',
                  node
                )
              "
            >
              {{ infoList[node].XGL1_L }}
            </div>
          </div>
        </div>
        <div class="left-list flex">
          <div class="list-flex1 flex align-cen">
            <div class="list-flex1-text">XGL2H</div>
            <div
              class="list-flex1-num list-flex1-num1"
              @click="
                toIpt(
                  infoList[node].XGL2_H,
                  '自适应相关变量2上限',
                  'XGL2_H',
                  node
                )
              "
            >
              {{ infoList[node].XGL2_H }}
            </div>
          </div>
          <div class="list-flex1 flex align-cen">
            <div class="list-flex1-text">XGL2L</div>
            <div
              class="list-flex1-num list-flex1-num1"
              @click="
                toIpt(
                  infoList[node].XGL2_L,
                  '自适应相关变量2下限',
                  'XGL2_L',
                  node
                )
              "
            >
              {{ infoList[node].XGL2_L }}
            </div>
          </div>
        </div>
        <div class="left-list flex">
          <div class="list-flex1 flex align-cen">
            <div class="list-flex1-text">PVDI</div>
            <div
              class="list-flex1-num list-flex1-num1"
              @click="
                toIpt(infoList[node].PVDI, '过程测量值判断死区', 'PVDI', node)
              "
            >
              {{ infoList[node].PVDI }}
            </div>
          </div>
          <div class="list-flex1 flex align-cen">
            <div class="list-flex1-text">UVDI</div>
            <div
              class="list-flex1-num list-flex1-num1"
              @click="
                toIpt(infoList[node].UVDI, '控制量判断死区', 'UVDI', node)
              "
            >
              {{ infoList[node].UVDI }}
            </div>
          </div>
        </div>
        <div class="left-list flex">
          <div class="list-flex1 flex align-cen">
            <div class="list-flex1-text">EKDB</div>
            <div
              class="list-flex1-num list-flex1-num1"
              @click="
                toIpt(
                  infoList[node].EKBDFDSP,
                  '自适应起作用偏差死区',
                  'EKBDFDSP',
                  node
                )
              "
            >
              {{ infoList[node].EKBDFDSP }}
            </div>
          </div>
          <div class="list-flex1 flex align-cen">
            <div
              class="list-flex1-btn"
              @click="
                chanereast(
                  infoList[node].RESET,
                  '确定要初始化自整定和自适应的参数吗',
                  'RESET',
                  node
                )
              "
            >
              参数复位
            </div>
            <div
              class="list-flex1-btn list-flex1-btn1"
              @click="
                chanereast(
                  infoList[node].ZZDMAN,
                  '确定要提取自整定参数吗？',
                  'ZZDMAN',
                  node
                )
              "
            >
              参数提取
            </div>
          </div>
        </div>
      </div>
      <!-- 先控左边数据结束 -->
      <!-- 先控右边开始 -->
      <div class="Firstcontrolflex-right">
        <!-- 头部开始 -->
        <div class="Firstcontrolflex-right-top flex">
          <div class="right-top-data1">
            <div class="data1 flex align-cen">
              <div class="data1-text">TL1</div>
              <div
                class="data1-ipt"
                @click="
                  toIpt(
                    infoList[node].TL1,
                    '软伺服作用时间，下位已经全部将4个作用时间参数统一，只需修改TL1',
                    'TL1',
                    node
                  )
                "
              >
                {{ infoList[node].TL1 }}
              </div>
              <div class="data1-ipt">
                {{ infoList[node].TL2 }}
              </div>
              <div class="data1-ipt">
                {{ infoList[node].TL3 }}
              </div>
              <div class="data1-ipt">
                {{ infoList[node].TL4 }}
              </div>
            </div>
            <div class="data1 flex align-cen">
              <div class="data1-text">E1</div>
              <div
                class="data1-ipt"
                @click="toIpt(infoList[node].E1, '软伺服偏差区间1', 'E1', node)"
              >
                {{ infoList[node].E1 }}
              </div>
              <div
                class="data1-ipt"
                @click="toIpt(infoList[node].E2, '软伺服偏差区间2', 'E2', node)"
              >
                {{ infoList[node].E2 }}
              </div>
              <div
                class="data1-ipt"
                @click="toIpt(infoList[node].E3, '软伺服偏差区间3', 'E3', node)"
              >
                {{ infoList[node].E3 }}
              </div>
              <div
                class="data1-ipt"
                @click="toIpt(infoList[node].E4, '软伺服偏差区间4', 'E4', node)"
              >
                {{ infoList[node].E4 }}
              </div>
            </div>
            <div class="data1 flex align-cen">
              <div class="data1-text">AO1</div>
              <div
                class="data1-ipt"
                @click="
                  toIpt(
                    infoList[node].AO1,
                    '软伺服偏差区间1对应输出幅度1',
                    'AO1',
                    node
                  )
                "
              >
                {{ infoList[node].AO1 }}
              </div>
              <div
                class="data1-ipt"
                @click="
                  toIpt(
                    infoList[node].AO2,
                    '软伺服偏差区间2对应输出幅度2',
                    'AO2',
                    node
                  )
                "
              >
                {{ infoList[node].AO2 }}
              </div>
              <div
                class="data1-ipt"
                @click="
                  toIpt(
                    infoList[node].AO3,
                    '软伺服偏差区间3对应输出幅度3',
                    'AO3',
                    node
                  )
                "
              >
                {{ infoList[node].AO3 }}
              </div>
              <div
                class="data1-ipt"
                @click="
                  toIpt(
                    infoList[node].AO4,
                    '软伺服偏差区间4对应输出幅度4',
                    'AO4',
                    node
                  )
                "
              >
                {{ infoList[node].AO4 }}
              </div>
            </div>
          </div>
          <div class="right-top-data2">
            <div class="data1 flex align-cen">
              <div class="data1-text">TL</div>
              <div
                class="data1-ipt"
                @click="
                  toIpt(
                    infoList[node].TL,
                    '防抖时间，同时对软伺服和重叠控制起作用',
                    'TL',
                    node
                  )
                "
              >
                {{ infoList[node].TL }}
              </div>
              <div class="data1-text">PO</div>
              <div
                class="data1-ipt"
                @click="toIpt(infoList[node].PT, '基本比例参数', 'PT', node)"
              >
                {{ infoList[node].PT }}
              </div>
              <div class="data1-text">KP</div>
              <div
                class="data1-ipt"
                @click="
                  toIpt(
                    infoList[node].KP,
                    '变比例系数，与SP和PV的差值有关',
                    'KP',
                    node
                  )
                "
              >
                {{ infoList[node].KP }}
              </div>
              <div class="data1-text">P</div>
              <div
                class="data1-ipt"
                @click="
                  toIpt(infoList[node].PX, '最终起作用的比例系数', 'PX', node)
                "
              >
                {{ infoList[node].PX }}
              </div>
              <div class="data1-text">SVH</div>
              <div
                class="data1-ipt"
                @click="
                  toIpt(
                    infoList[node].SVH,
                    '变积分起作用阀值，该值为PVMU-PVMD的百分比',
                    'SVH',
                    node
                  )
                "
              >
                {{ infoList[node].SVH }}
              </div>
            </div>
            <div class="data1 flex align-cen">
              <div class="data1-text" style="visibility: hidden">TL</div>
              <div class="data1-ipt" style="visibility: hidden">
                <el-input
                  placeholder=""
                  v-model="infoList.V_GONGGONG__p__ZGXK01.TL"
                  v-if="isIndex === 1"
                ></el-input>
              </div>
              <div class="data1-text">TIO</div>
              <div
                class="data1-ipt"
                @click="toIpt(infoList[node].TI, '基本积分系数', 'TI', node)"
              >
                {{ infoList[node].TI }}
              </div>
              <div class="data1-text">KI</div>
              <div
                class="data1-ipt"
                @click="
                  toIpt(
                    infoList[node].KI,
                    '变积分系数，该值与SP和PV的差值有关',
                    'KI',
                    node
                  )
                "
              >
                {{ infoList[node].KI }}
              </div>
              <div class="data1-text">TI</div>
              <div
                class="data1-ipt"
                @click="
                  toIpt(infoList[node].TIi, '最终起作用的积分系数', 'TIi', node)
                "
              >
                {{ infoList[node].TIi }}
              </div>
              <div class="data1-text" style="visibility: hidden">SVH</div>
              <div class="data1-ipt" style="visibility: hidden">
                <el-input
                  placeholder=""
                  v-model="infoList.V_GONGGONG__p__ZGXK01.SVH"
                  v-if="isIndex === 1"
                ></el-input>
              </div>
            </div>
            <div class="data1 flex align-cen">
              <div class="data1-text">BLK</div>
              <div
                class="data1-ipt"
                @click="
                  toIpt(infoList[node].ZF_K, '软伺服保留系数', 'ZF_K', node)
                "
              >
                {{ infoList[node].ZF_K }}
              </div>
              <div class="data1-text">TD</div>
              <div
                class="data1-ipt"
                @click="toIpt(infoList[node].TD, '基本微分系数', 'TD', node)"
              >
                {{ infoList[node].TD }}
              </div>
              <div class="data1-text">KD</div>
              <div
                class="data1-ipt"
                @click="
                  toIpt(
                    infoList[node].KD,
                    '变微分系数，该值与SP和PV的差值有关',
                    'KD',
                    node
                  )
                "
              >
                {{ infoList[node].KD }}
              </div>
              <div class="data1-text">DI</div>
              <div
                class="data1-ipt"
                @click="
                  toIpt(infoList[node].DI, '最终起作用的微分系数', 'DI', node)
                "
              >
                {{ infoList[node].DI }}
              </div>
              <div class="data1-text" style="font-size: 12px">SPRH</div>
              <div
                class="data1-ipt"
                @click="
                  toIpt(
                    infoList[node].SP_RH,
                    'SP速率限幅，该值为PVMU-PVMD的百分比',
                    'SP_RH',
                    node
                  )
                "
              >
                {{ infoList[node].SP_RH }}
              </div>
            </div>
          </div>
        </div>
        <!-- 头部结束 -->
        <!-- 曲线图开始 -->
        <div class="Firstcontrolflex-right-cen flex">
          <div class="right-cen-echarts">
            <div id="rts_Chart" v-loading="loading" :option="option"></div>
          </div>
          <div class="right-cen-number" >
            <div class="typedata">
              <div class="typedatabox" style="color: #25495e">
                <div>设定值</div>
                <div>Y[{{ infoList[node].SP }}]</div>
                <div>Y[{{ name[0] }}]</div>
              </div>
              <div class="typedatabox typedatabox1" style="color: #0de0ac">
                <div>测量值</div>
                <div>Y[{{ infoList[node].PV }}]</div>
                <div>Y[{{ name[1] }}]</div>
              </div>
              <div class="typedatabox" style="color: #ec163e">
                <div>总输出</div>
                <div>Y[{{ infoList[node].AV}}]</div>
                <div>Y[{{ name[2]}}]</div>
              </div>
              <div class="typedatabox" style="color:#f2c334">
                      <div>PID输出</div>
                      <div>Y[{{infoList[node].AV_P}}]</div>
                       <div>Y[{{name[3]}}]</div>
                  </div>
                  <div class="typedatabox" style="color:#ec24ea">
                      <div>RSF输出</div>
                      <div>Y[{{infoList[node].AV_R}}]</div>
                       <div>Y[{{name[4]}}]</div>
                  </div>
                   <div class="typedatabox" style="color:#e00d5d">
                      <div>观测器输出</div>
                      <div>Y[{{infoList[node].AV_J}}]</div>
                       <div>Y[{{name[6]}}]</div>
                  </div>
                   <div class="typedatabox" style="color:#fe771d">
                      <div>观测器静态输出</div>
                      <div>Y[{{infoList[node].AV_GC}}]</div>
                       <div>Y[{{name[5]}}]</div>
                  </div>
                   <div class="typedatabox" style="color:#34f0f2">
                      <div>观测器动态输出</div>
                      <div>Y[{{infoList[node].AV_D}}]</div>
                       <div>Y[{{name[8]}}]</div>
                  </div>
                   <div class="typedatabox" style="color:#1662ec">
                      <div>CD输出</div>
                      <div>Y[{{infoList[node].AV_C}}]</div>
                       <div>Y[{{name[7]}}]</div>
                  </div>
            </div>
          </div>
        </div>
        <!-- 曲线图结束 -->
        <div class="Firstcontrolflex-right-bot">
          <div class="bot-comf flex">
            <div class="right-btn flex">
              <!-- <div class="preservation" @click="downloadImpByChart">保存</div> -->
              <!-- <div class="load">载入</div> -->
            </div>
          </div>
          <!-- -->
        </div>
      </div>
      <!-- 先控右边结束 -->
    </div>
    <div class="bot-condition flex align-cen">
      <div class="condition-list flex">
        <div
          @click="
            toIpt(infoList[node].PCMMS, '输入0或1改变状态', 'PCMMS', node)
          "
        >
          {{ infoList[node].PCMMS == 0 ? "并联" : "串联" }}
        </div>
        <div @click="toIpt(infoList[node].RSFEN, '点击按钮', 'RSFEN', node)">
          {{ infoList[node].RSFEN ? "RSF投用" : "RSF切除" }}
        </div>
        <div @click="toIpt(infoList[node].GCEN, '点击按钮', 'GCEN', node)">
          {{ infoList[node].GCEN ? "GC投用" : "GC切除" }}
        </div>
        <div @click="toIpt(infoList[node].CDEN, '点击按钮', 'CDEN', node)">
          {{ infoList[node].CDEN ? "CD投用" : "CD切除" }}
        </div>
        <div @click="toIpt(infoList[node].XZEN, '点击按钮', 'XZEN', node)">
          {{ infoList[node].XZEN ? "XZ投用" : "XZ切除" }}
        </div>
        <div
          @click="
            chanereast(
              infoList[node].ZSYZZD && infoList[node].ZSYZZD == 1 ? '254' : '1',
              '是否改变自整定功能的选择',
              'ZSYZZD',
              node
            )
          "
        >
          自整定
        </div>
        <div
          @click="
            chanereast(
              infoList[node].ZSYZZD && infoList[node].ZSYZZD == 1 ? '253' : '2',
              '是否改变自适应功能的选择？',
              'ZSYZZD',
              node
            )
          "
        >
          自适应
        </div>
      </div>
      <!-- <div class="condition-btn flex">
               <div>实时趋势</div>
               <div>后翻一页</div>
               <div>后翻半页</div>
               <div>运行</div>
               <div>前翻半页</div>
               <div>前翻一页</div>
             </div> -->
      <div class="condition-time flex">
        <div @click="serchHis(1)">1h</div>
        <div @click="serchHis(2)">2h</div>
        <div @click="serchHis(4)">4h</div>
        <div @click="serchHis(8)">8h</div>
        <div @click="serchHis(24)">24h</div>
        <div @click="serchHis(72)">72h</div>
      </div>
    </div>
    <inputVal ref="inputVal" @getTreeData="DataJson(arguments)"></inputVal>
  </div>
</template>
<script>
import { getEchars } from "@/api/index/index";
import inputVal from "@/components/inputVal.vue"; //输入框组件
import { downAction } from "@/api/index/index";
export default {
  name: "Firstcontrol",
  components: { inputVal },
  props: {
    infoList: {
      type: Object,
      default: {}, // 默认值
    },
    isIndex: [String, Number],
    historyname: [String, Number], //先控名字区分
    node: [String, Number], //节点名
    titname: [String, Number], //tit名字
  },

  data: () => {
    return {
      comName: "",
      occupy: 0,
      name: [],
      name1: [],
      mark: [],
      nodeList: [],
      xAxis: [],
      echartData: [],
      legendData: [],
      timer: null,
      myChart: "",
      loading: true,
      echartcolor: [],
      end_time: "",
      start_time: "",
      scaleHeight: "",
      option: "",
      rightArr:[]
    };
  },
  watch: {
    infoList: {
      handler(n, o) {
        this.infoList = n;
      },
      deep: true, // 深度监听父组件传过来对象变化
    },
    historyname: {
      handler(n, o) {
        this.historyname = n;
      },
      deep: true, // 深度监听父组件传过来对象变化
    },
    node: {
      handler(n, o) {
        this.node = n;
        this.getInfo();
      },
      deep: true, // 深度监听父组件传过来对象变化
    },
    option: {
      handler(newVal, oldVal) {
        if (this.myChart) {
          if (newVal) {
            this.loading = false;
            this.myChart.setOption(newVal, true);
          } else {
            this.loading = false;
            this.myChart.setOption(oldVal);
          }
        } else {
          this.loading = false;
          this.drawLine();
        }
      },
    },
  },
  created() {
      this.mark = [
        "SP",
        "PV",
        "AV",
        "AV_P",
        "AV_R",
        "AV_GC",
        "AV_J",
        "AV_C",
        "AV_D",
      ];
      this.name = [
        this.historyname + "_SP_WF",
        this.historyname + "_PV_WF",
        this.historyname + "_AV_WF",
        this.historyname + "_AV_P_WF",
        this.historyname + "_AV_R_WF",
        this.historyname + "_AV_GC_WF",
        this.historyname + "_AV_J_WF",
        this.historyname + "_AV_C_WF",
        this.historyname + "_AV_D_WF",
      ];
      this.nodeList = [
        this.node,
        this.node,
        this.node,
        this.node,
        this.node,
        this.node,
        this.node,
        this.node,
        this.node,
      ];
      this.echartcolor = [
        "#25495e",
        "#0de0ac",
        "#ec163e",
        "#f2c334",
        "#ec24ea",
        "#0dd9e0",
        "#e00d5d",
        "#1662ec",
        "#0de0ac",
      ];
    // this.comName =
    //   this.historyname == 'ZGXK01' ? this.infoList.V_GONGGONG__p__ZGXK01.SP : this.historyname == 'ZGXK02' ? this.infoList.V_GONGGONG__p__ZGXK02.SP:this.historyname == 'ZGXK03' ? this.infoList.V_GONGGONG__p__ZGXK03.SP:this.historyname == 'ZGXK04' ? this.infoList.V_GONGGONG__p__ZGXK04.SP:'';
  },
  mounted() {
    this.getInfo();
    this.timer = setInterval(() => {
      setTimeout(this.getInfo(), 0);
    }, 5000);
    // 通过$once来监听定时器，在beforeDestroy钩子可以被清除。
    this.$once("hook:beforeDestroy", () => {
      clearInterval(this.timer);
    });
    setTimeout(() => {
      //DOM还没更新
      this.$nextTick(() => {
        //DOM现在更新了
        this.bodyScale();
      });
    }, 100);
  },
  methods: {
  

    // 自适应
    bodyScale() {
      var devicewidth = document.documentElement.clientWidth; //获取当前分辨率下的可是区域宽度
      var deviceheight = document.documentElement.clientHeight; //获取当前分辨率下的可是区域高度
      var scale = devicewidth / 1920; // 分母——设计稿的尺寸
      this.scaleHeight = [
        { transform: "scale(" + scale + ")" },
        { "transform-origin": "center center" },
      ];
    },
    //  查看过去趋势
    serchHis(val) {
      this.end_time = this.$util.formatDate("yyyy-MM-dd mm:hh:ss");
      this.start_time = this.$util.getTimeData(val);
      this.getInfo();
    },
    // 获取自组件传过来的值
    DataJson(data) {
      let strNode = data[0];
      let strMark = data[1];
      this.$nextTick(() => {
        this.infoList[strNode][strMark] = data[2];
      });
    },
    // 参数获取
    chanereast(val, name, mark, node) {
      let projectData = JSON.parse(localStorage.getItem("info"));
      this.$confirm(name, {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          if (typeof val == "boolean") {
            val = !val;
          }
          downAction({
            device: projectData.device,
            mark: mark,
            node: node,
            value: val,
            cp_type: "rfl",
          })
            .then((res) => {
              if (res.info.res == 1) {
                this.$message.success("成功");
              } else {
                this.$message.error("出错了");
              }
            })
            .catch((err) => {
              this.$message.error("下置失败");
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    // 自动手动跟踪
    chaneStu(val, mark, node) {
      let projectData = JSON.parse(localStorage.getItem("info"));
      downAction({
        device: projectData.device,
        mark: mark,
        node: node,
        value: val,
        cp_type: "rfl",
      })
        .then((res) => {
          if (res.info.res == 1) {
          } else {
          }
        })
        .catch((err) => {});
    },
    // 打开对应历史趋势
    tocomment(name, color) {
      let projectData = JSON.parse(localStorage.getItem("info"));
      this.mark = [name];
      this.name = [this.historyname + "_" + name + "_WF"];
      this.nodeList = [this.node];
      this.loading = true;

      getEchars({
        device: projectData.device,
        mark: this.mark,
        name: this.name,
        node: this.nodeList,
        cp_type: "rfl",
      })
        .then((res) => {
          this.xAxis = res.data.x;
          this.echartData = res.data.series;
          this.echartcolor = [color];
                   this.option = {
            title: {
              text: "",
            },
            tooltip: {
              trigger: "axis",
            },
            color: this.echartcolor,
            legend: {
              data: this.echartData.length == 1 ? this.name1 : this.name,
            },
            grid: {
              left: "3%",
              right: "4%",
              bottom: "10%",
              containLabel: true,
            },
            toolbox: {
              feature: {
                saveAsImage: {},
              },
            },
            xAxis: {
              type: "category",
              boundaryGap: false,
              data: this.xAxis,
            },
            yAxis: {
              type: "value",
              name: "",
              min: 0,
              position: "left",
              axisLabel: {
                formatter: "{value}",
              },
            },
            series: this.echartData,
            dataZoom: [
              {
                //默认为滚动条型数据区域缩放组件
                show: true,
              
                start: 100,
                end: 0,
                // 开始位置的数值
                startValue: 100,
                // 结束位置的数值
                endValue: 0,
              },
            ],
          };
        })
        .catch((err) => {
          this.loading = false;
          // this.$message.error('数据获取失败');
        });
    },
    //    打开输入框组件。
    toIpt(data, name, historyname, node) {
      this.$refs.inputVal.open(data, name, historyname, node);
    },
    //    保存折线图片
    downloadImpByChart() {
      var url = this.myChart.getConnectedDataURL({
        pixelRatio: 5, //导出的图片分辨率比率,默认是1
        backgroundColor: "dark", //图表背景色
        excludeComponents: [
          //保存图表时忽略的工具组件,默认忽略工具栏
          "toolbox",
        ],
        type: "png", //图片类型支持png和jpeg
      });
      var $a = document.createElement("a");
      var type = "png";
      $a.download = this.myChart.getOption().title[0].text + "." + type;
      $a.target = "_blank";
      $a.href = url;
      // Chrome and Firefox
      if (typeof MouseEvent === "function") {
        var evt = new MouseEvent("click", {
          view: window,
          bubbles: true,
          cancelable: false,
        });
        $a.dispatchEvent(evt);
      }
      // IE
      else {
        var html = "";

        ('<body style="margin:0;">');
        "![](" + url + ")";
        ("</body>");
        var tab = window.open();
        tab.document.write(html);
      }
    },
    // 获取折线图数据
    getInfo() {
      // this.echartData = [];
      // this.xAxis = [];
      let projectData = JSON.parse(localStorage.getItem("info"));
      let params;
      if (this.start_time) {
        params = {
          device: projectData.device,
          mark: this.mark,
          name: this.name,
          node: this.nodeList,
          end_time: this.end_time,
          start_time: this.start_time,
          cp_type: "rfl",
        };
      } else {
        params = {
          device: projectData.device,
          mark: this.mark,
          name: this.name,
          node: this.nodeList,
          cp_type: "rfl",
        };
      }
      getEchars(params)
        .then((res) => {
          this.loading = false;
          this.xAxis = res.data.x;
          this.echartData = res.data.series;
          this.option = {
            title: {
              text: "",
            },
       tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross'
          },
          appendToBody:'html',
          className: 'echarts-tooltip echarts-tooltip-dark',
          position: [720, 10] 
  },
            color: this.echartcolor,
            legend: {
              data: this.echartData.length == 1 ? this.name1 : this.name,
            },
            grid: {
              left: "3%",
              right: "4%",
              bottom: "10%",
              containLabel: true,
            },
            toolbox: {
              feature: {
                saveAsImage: {},
              },
            },
            xAxis: {
              type: "category",
              boundaryGap: false,
               axisTick: {
        alignWithLabel: true
      },
                axisLine: {
                  onZero: false,
                  lineStyle: {
                  }
                },
                  axisPointer: {
                    label: {
                    width:700,
                    lineHeight:20,
                     margin:20,
                      show:false,
                    overflow:'breakAll',
                      formatter: function (params) {   
                        return  params.value
                      }
                    }
                  },
              data: this.xAxis,
            },
            yAxis: {
              type: "value",
              name: "",
              min: 0,
              position: "left",
              axisLabel: {
                formatter: "{value}",
              },
            },
            series: this.echartData,
            dataZoom: [
              {
                  //默认为滚动条型数据区域缩放组件
                show: true,
                xAxisIndex: [0],
                start: 100,
                end: 0,
                // 开始位置的数值
                startValue: 100,
                // 结束位置的数值
                endValue: 0,
              }
            ],
          };
          // this.drawLine()
          // this.loading = false
        })
        .catch((err) => {
          this.loading = false;
          // this.$message.error('数据获取失败');
        });
    },
    // 曲线图
    drawLine() {
      // 基于准备好的dom，初始化echarts实例
      let myChart = this.$echarts.init(
        document.getElementById("rts_Chart"),
        "dark"
      );
      this.myChart = myChart;
      // 绘制图表
      myChart.setOption(this.option,true);
      this.myChart.on('click',function(params){
        console.log(params)
      })
    },
    closeCompon() {
      this.$emit("sendStatus", "Firstcontrol", false);
    },
  },
};
</script>
<style lang="scss" scoped>
.Firstcontrol {
  width: 1256px;
  height: 888px;
  background: #e9f7ff;
  cursor: move;
  border-radius: 8px;
  font-family: ArialNarrow;
  .evaluate-header {
    height: 50px;
    background: #58cdf4;
    line-height: 50px;
    text-align: center;
    color: #0a4c62;
    font-size: 24px;
    position: relative;
    border-radius: 5px 5px 0 0;
    .evaluate-header-icon {
      width: 32px;
      height: 32px;
      position: absolute;
      right: 10px;
      top: 8px;
      border-radius: 32px;
      border: 1px solid #041a28;
      color: #041a28;
      line-height: 32px;
      cursor: pointer;
    }
  }
  .Firstcontrolflex {
    margin: 10px;
    .Firstcontrolflex-left {
      width: 23%;
      .left-list {
        width: 100%;
        .list-flex1 {
          width: 50%;
          color: #041a28;
          line-height: 33px;
          .list-flex1-min {
            font-size: 20px;
            margin-right: 5px;
            background: rgba(158, 162, 164, 0.1);
            width: 33px;
            height: 22px;
            line-height: 22px;
            text-align: center;
          }
          .list-flex1-text {
            width: 36%;
            text-align: right;
            font-size: 18px;
            color: #22465b;
          }
          .list-flex1-num {
            width: 80px;
            height: 28px;
            line-height: 28px;
            text-align: center;
            background: rgba(158, 162, 164, 0.1);
            border-radius: 2px;
            margin-left: auto;
            color: #25495e;
            font-size: 18px;
            margin-right: 12px;
          }
          .list-flex1-num1 {
            background-color: rgba(0, 22, 36, 0.1) !important;
            cursor: pointer;
          }
          .list-flex1-btn {
            font-size: 12px;
            background: rgb(192, 229, 250);
            width: 62px;
            height: 26px;
            text-align: center;
            line-height: 26px;
            cursor: pointer;
          }
          .list-flex1-btn1 {
            margin-left: auto;
            background: rgb(69, 213, 246);
            color: #fff;
            margin-right: 12px;
          }
        }
      }
    }
    .Firstcontrolflex-right {
      width: 75%;
      margin-left: auto;
      .Firstcontrolflex-right-top {
        width: 100%;
        .right-top-data1 {
          width: 40%;
          .data1-text {
            text-align: right;
            color: #22465b;
            font-size: 18px;
            line-height: 31px;
            text-indent: right;
            width: 35px;
          }
          .data1-ipt {
            width: 80px;
            height: 31px;
            background-color: rgba(0, 22, 36, 0.1);
            color: #25495e;
            font-size: 18px;
            line-height: 31px;
            text-align: center;
            margin-top: 5px;
            border-radius: 2px;
            margin-left: 5px;
            cursor: pointer;
          }
        }
        .right-top-data2 {
          width: 60%;
          .data1-text {
            margin-left: 5px;
            color: #22465b;
            font-size: 18px;
            line-height: 31px;
            text-align: right;
            width: 35px;
          }
          .data1-ipt {
            width: 80px;
            height: 31px;
            background-color: rgba(0, 22, 36, 0.1);
            font-size: 18px;
            line-height: 31px;
            text-align: center;
            margin-top: 5px;
            border-radius: 2px;
            color: #25495e;
            margin-left: 5px;
            cursor: pointer;
          }
        }
      }
      .Firstcontrolflex-right-cen {
        margin-top: 20px;
        .right-cen-echarts {
          width: 80%;
          #rts_Chart {
            width: 730px;
            height: 600px;
          }
        }
        .right-cen-number {
          width: 188px;
          height: 600px;
          line-height: 22px;
          .typedata {
            width: 100%;
            height: inherit;
            background: rgba(158, 162, 164, 0.1);
            border-radius: 2px;
            padding-top: 10px;
            .typedatabox {
              padding: 0 10px;
              color: #041a28;
              font-size: 14px;
            }
            .typedatabox1 {
              color: #33e0b3;
            }
          }
        }
      }
      .Firstcontrolflex-right-bot {
        padding: 10px;
        width: 730px;
        .right-btn {
          margin-left: auto;
          div {
            padding: 2px 20px;
            background: #e0eef6;
          }
          .preservation {
            margin-right: 10px;
          }
          .bot-comf {
            width: 682px;
          }
        }
      }
    }
  }
  .bot-condition {
    padding: 0 10px;
    .condition-list {
      div {
        font-size: 12px;
        border-right: 1px solid #d3e4ec;
        padding-right: 10px;
        padding-left: 5px;
      }
      div:last-child {
        border-right: none;
      }
    }
    .condition-btn {
      div {
        padding: 4px 10px;
        background: #d3e4ec;
        margin-left: 10px;
        font-size: 13px;
      }
    }
    .condition-time {
      margin-left: auto;
      margin-top: -20px;
      div {
        width: 40px;
        height: 30px;
        background-color: #d4e3ec;
        border-radius: 2px;
        line-height: 30px;
        text-align: center;
        font-size: 20px;
        color: #94acba;
        text-shadow: 0px 1px 0px 0px #ffffff;
        margin-right: 3px;
        cursor: pointer;
      }
    }
  }
}
::v-deep {
  .left-tit {
    background-color: #d4e3ec;
    text-align: center;
    outline: none;
    border: none;
    border-radius: 0;
    height: 32px;
    line-height: 32px;
    font-size: 20px;
    color: #22465b;
    padding: 0 20px;
  }
  .list-flex1-num,
  .data1-ipt {
    .el-input__inner {
      background-color: transparent;
      color: #25495e;
      text-align: center;
      width: 100%;
      outline: none;
      border: none;
      height: inherit;
      line-height: inherit;
      font-size: 20px;
      padding: 0;
    }
  }
  .defultcolor1 {
    color: #f2c334 !important;
  }
  .defultcolor2 {
    color: #ec24ea !important;
  }
  .defultcolor3 {
    color: #0dd9e0 !important;
  }
  .defultcolor4 {
    color: #e00d5d !important;
  }
  .defultcolor5 {
    color: #1662ec !important;
  }
  .defultcolor6 {
    color: #0de0ac !important;
  }
}
</style>